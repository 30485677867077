/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import { Avatar, Button, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronRight from '../../../../assets/img/chevron-right.png';
import chevronLeft from '../../../../assets/img/svg/Chevron.svg';
import { IGetPersonalDetailsResponse } from '../../../../clients/AccountClient';
import {
  IDebitOrderSchedule,
  IGetRepayStatusResponse,
  IOpenLoanDetailsResponse,
  TransactionTypeEnum,
} from '../../../../clients/LoanClient';
import NavigationButton from '../../../../components/Buttons/NavigationButton';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import TextButton from '../../../../components/Buttons/TextButton';
import DebitOrderSchedule from '../../../../components/DebitOrderSchedule/DebitOrderSchedule';
import RepayNoticeModal from '../../../../components/RepayNoticeModal/RepayNoticeModal';
import { formatDateWithDefault } from '../../../../utils/Helpers/DateHelpers';
import { formatMoney } from '../../../../utils/Helpers/SliderHelperFunctions';
import { RouterRoutes } from '../../../../utils/RouterRoutes';
import FeesBreakdown from '../FeesBreakdown/FeesBreakdown';
import Transactions from '../Transactions/Transactions';
import LoanDetails from '../../../../components/LoanDetails/LoanDetails';

interface OpenLoan {
  openLoanResponse: IOpenLoanDetailsResponse;
  personalDetails: IGetPersonalDetailsResponse;
  repayStatus: IGetRepayStatusResponse | null;
  debitOrders: IDebitOrderSchedule[];
}

const ActiveLoan: React.FunctionComponent<OpenLoan> = ({
  openLoanResponse,
  personalDetails,
  repayStatus,
  debitOrders,
}: OpenLoan) => {
  const {
    collectionDates,
    term,
    loanAmount,
    termInDays,
    totalServiceFees,
    interestAmount,
    initiationFee,
    serviceFee,
    insuranceFee,
    totalRepayable,
    deathBenefit,
  } = openLoanResponse.quotationData || {};
  const { currentBalance } = openLoanResponse.loanData || {};
  const { firstName } = personalDetails.personalDetails?.customer || {};
  const navigate = useNavigate();
  const [showRepayModal, setShowRepayModal] = useState<boolean>(false);
  const isInstallmentLoan = collectionDates?.length! > 0;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const repayEnabled = repayStatus?.enabled === true;
  const [showLoanDetails, setShowLoanDetails] = useState<boolean>(false);
  const [showFeesBreakdown, setShowFeesBreakdown] = useState<boolean>(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState<boolean>(false);

  const navigateToRepay = () => {
    navigate(`${RouterRoutes.repay}`);
  };

  const showDetails = () => {
    setShowLoanDetails(true);
  };

  const hideDetails = () => {
    setShowLoanDetails(false);
  };

  const showTransactions = () => {
    setShowTransactionHistory(true);
  };

  const hideTransactions = () => {
    setShowTransactionHistory(false);
  };

  const feesBreakdownHandler = () => {
    setShowFeesBreakdown(!showFeesBreakdown);
  };

  const calculateTotalInstalments = (): number => {
    if (isInstallmentLoan) {
      return collectionDates?.length ?? 0;
    } else {
      return 1;
    }
  };

  const calculateCompletedDebitOrders = (): number => {
    if (isInstallmentLoan) {
      return (
        (collectionDates?.length ?? 0) -
        debitOrders.filter((item) => item.transactionType !== TransactionTypeEnum.Cashout).length
      );
    } else {
      return 0;
    }
  };

  if (showLoanDetails)
    return (
      <Container>
          <Button
            onClick={hideDetails}
            sx={{
              padding: 0,
              minWidth: '5rem',
              textDecoration: 'none',
              color: 'inherit',
              '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
            }}
          >
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              marginBottom={{ xs: '2rem', md: '2.4rem' }}
            >
              <Avatar
                src={chevronLeft}
                alt="Back button icon"
                sx={{
                  width: '1.4rem',
                  height: '1.4rem',
                  marginRight: '0.4rem',
                }}
              />
              <Typography
                display={'inline'}
                fontSize={'1.5rem'}
                fontWeight={'300'}
                lineHeight={{ xs: '2.2rem' }}
                letterSpacing={{ xs: '-.0.04rem' }}
              >
                Back
              </Typography>
            </Stack>
          </Button>
          <LoanDetails
            isInstalmentLoan={isInstallmentLoan}
            loanAmount={loanAmount ?? 0}
            totalInterestAndFees={(totalServiceFees ?? 0) + (interestAmount ?? 0)}
            term={isInstallmentLoan ? term ?? 0 : termInDays ?? 0}
            onShowFeesBreakdown={feesBreakdownHandler}
          />
          {showFeesBreakdown ? (
            <FeesBreakdown
              initiationFee={initiationFee}
              serviceFee={serviceFee}
              totalInterest={interestAmount}
              insuranceFee={insuranceFee}
              feesBreakdownHandler={feesBreakdownHandler}
              totalRepayable={(totalRepayable ?? 0) + (deathBenefit?.contractValue ?? 0)}
              deathBenefitContractValue={deathBenefit?.contractValue ?? 0}
            />
        ) : null}
      </Container>
    );

  if (showTransactionHistory)
    return (
      <>
        <Button
          onClick={hideTransactions}
          sx={{
            marginLeft: 2,
            padding: 0,
            minWidth: '5rem',
            textDecoration: 'none',
            color: 'inherit',
            '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' },
          }}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            marginBottom={{ xs: '2rem', md: '2.4rem' }}
          >
            <Avatar
              src={chevronLeft}
              alt="Back button icon"
              sx={{
                width: '1.4rem',
                height: '1.4rem',
                marginRight: '0.4rem',
              }}
            />
            <Typography
              display={'inline'}
              fontSize={'1.5rem'}
              fontWeight={'300'}
              lineHeight={{ xs: '2.2rem' }}
              letterSpacing={{ xs: '-.0.04rem' }}
            >
              Back
            </Typography>
          </Stack>
        </Button>
        <Transactions loanId={openLoanResponse.loanData?.loanId}></Transactions>
      </>
    );

  return (
    <>
      <Container>
        {showRepayModal && <RepayNoticeModal showModal={showRepayModal} onClose={navigateToRepay} />}
        <Typography
          variant="h1"
          fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
          fontWeight={400}
          lineHeight={'3.2rem'}
          letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }} 
          padding={isMobile ? '0.8rem 0rem 0rem 0rem' : '0rem 1rem 0rem 1rem'}
        >
          Hi {firstName}
        </Typography>
        <Typography
          variant="body1"
          fontSize={'3.2rem'}
          fontWeight={600}
          lineHeight={'2.2rem'}
          letterSpacing={'-0.04em'}
          textAlign={'center'}
          marginTop={isMobile ? '2.4rem' : '2.8rem'}
          color={theme.palette.primary.dark}
        >
          R {formatMoney(currentBalance ?? 0)}
        </Typography>
        <Stack
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          marginTop={'1.1rem'}
          marginBottom={'2rem'}
        >
          <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'1.7rem'}>
            Settlement balance
          </Typography>
          <Typography variant="body1" fontSize={'1.4rem'} fontWeight={300} lineHeight={'1.7rem'}>
            {`${formatDateWithDefault(undefined, 'dd MMM yyyy')}`}
          </Typography>
        </Stack>

        <DebitOrderSchedule
          debitOrders={debitOrders}
          totalInstalments={calculateTotalInstalments()}
          completedDebitOrders={calculateCompletedDebitOrders()}
        />
        <TextButton onClick={showTransactions} removePadding>
          <Typography fontSize={'16px'}>View full transaction history</Typography>
        </TextButton>
        <Stack
          borderTop={'1px solid #E4E4E4'}
          sx={{ padding: '1rem 0rem 2rem 0rem' }}
          spacing={{ xs: '2rem', sm: '3.2rem' }}
        >
          {repayEnabled && (
            <Stack display={'flex'} flexDirection={'column'} alignItems={{ xs: 'center', sm: 'flex-end' }}>
              {/* Line height is set to 5rem because that's the height on loan application details button and they have to align*/}
              <Typography variant="body1" fontSize={'1.5rem'} fontWeight={300} lineHeight={'5rem'}>
                <Typography
                  component="span"
                  variant="body1"
                  fontSize={'1.5rem'}
                  fontWeight={500}
                  lineHeight={'2.2rem'}
                >
                  Settle
                </Typography>{' '}
                or{' '}
                <Typography
                  component="span"
                  variant="body1"
                  fontSize={'1.5rem'}
                  fontWeight={500}
                  lineHeight={'2.2rem'}
                >
                  repay any amount
                </Typography>{' '}
                towards your loan
              </Typography>
              <PrimaryButton onClick={() => setShowRepayModal(true)}>Make payment</PrimaryButton>
            </Stack>
          )}
          <NavigationButton onClick={showDetails}>
            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
              <Typography fontSize={'1.6rem'} letterSpacing={'-0.4px'} fontWeight={400}>
                Loan application details
              </Typography>
              <img src={ChevronRight} alt="Chevron right" />
            </Stack>
          </NavigationButton>
        </Stack>
      </Container>
    </>
  );
};

export default ActiveLoan;
