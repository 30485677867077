/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  EmploymentDetails,
  ErrorResponse,
  GetAffordabilityResponse,
  GetCustomerResponse,
  GetEmploymentResponse,
  IEmploymentDetails,
  IGetAffordabilityResponse,
  IGetCustomerResponse,
  IGetEmploymentResponse,
} from '../../../../../clients/AccountClient';
import { IOpenLoanDetailsResponse } from '../../../../../clients/LoanClient';
import InputDisplayOnly from '../../../../../components/Input/DisplayOnly/InputDisplayOnly';
import Loading from '../../../../../components/Loading/Loading';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import useAccountClient from '../../../../../hooks/account/Client';
import useLoanClient from '../../../../../hooks/loan/Client';
import '../../../../../styles/_buttons.scss';
import '../../../../../styles/_forms.scss';
import { FULL_COLUMN_SIZE, INPUT_COLUMN_SIZE, LABEL_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import TextButton from '../../../../../components/Buttons/TextButton';
import sharedStyles from '../_common.module.scss';
import ChangeEmploymentDetailsLN from './ChangeEmployment';
import ChangeSalaryDayLN from './ChangeSalaryDay';
import ChangeAffordabilityLN from './ChangeAffordability';
import { useTracking } from '../../../../../Tracking/TrackingContext';

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const ConfirmAffordabilityDetailsPage: React.FunctionComponent = () => {
  const { TrackError } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [employmentDetailsResponse, setEmploymentDetailsResponse] = useState<IGetEmploymentResponse>(
    new GetEmploymentResponse()
  );
  const [openLoanDetailsResponse, setOpenLoanDetailsResponse] = useState<IOpenLoanDetailsResponse | null>(null);
  const [customerDetailResponse, setCustomerDetailResponse] = useState<IGetCustomerResponse>(new GetCustomerResponse());
  const [affordabilityDetailsResponse, setAffordabilityDetailsResponse] = useState<IGetAffordabilityResponse>(
    new GetAffordabilityResponse()
  );
  const showChangeEmploymentDetails = useRef(false);
  const showChangeSalaryDay = useRef(false);
  const accountClient = useAccountClient();
  const loanClient = useLoanClient();
  const { displaySnackBar } = useSnackBar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const allowedToUpdate =
    openLoanDetailsResponse?.hasOpenLoan == false ||
    !(
      openLoanDetailsResponse?.openLoanStatus == 6 ||
      openLoanDetailsResponse?.openLoanStatus == 7 ||
      openLoanDetailsResponse?.openLoanStatus == 8
    );

  const updateSalaryDay = async (employmentResponse: GetEmploymentResponse) => {
    const applyValuesString = localStorage.getItem('applyValues');
    const applyValues = JSON.parse(applyValuesString ?? '');

    /*
    Here we are checking if the salary day is 0 if it is then set the local storage value the same as what we have stored in the database.
    If the salary day is not 0 and is different from the one in the employment page then update the salary day in the database.
    */
    if (applyValues && applyValues.salaryDay === 0) {
      localStorage.setItem(
        'applyValues',
        JSON.stringify({ ...applyValues, salaryDay: employmentResponse.employment?.salaryDay })
      );
    } else if (
      applyValues &&
      applyValues.salaryDay !== 0 &&
      applyValues.salaryDay !== employmentResponse.employment?.salaryDay
    ) {
      const employmentDetails: IEmploymentDetails = {
        ...employmentResponse.employment,
        salaryDay: applyValues.salaryDay,
      };
      try {
        await accountClient.updateEmployment(new EmploymentDetails(employmentDetails));
      } catch (error) {
        if (error instanceof ErrorResponse && error.errorCode === 400) {
          TrackError(error, error.message);
          displaySnackBar(error.message ?? "An validation error has occurred", 'error');
        } else {
          displaySnackBar('Oops, an error has occurred please try again', 'error');
        }
      }
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const employmentDetailPromise = accountClient.getEmployment();
      const openLoanDetailsPromise = loanClient.getOpenLoanDetails(undefined);
      const customerDetailPromise = accountClient.getCustomer();
      const affordabilityPromise = accountClient.getAffordability();
      const [employmentDetailResponse, openLoanDetailsResponse, customerDetailResponse, affordabilityResponse] = await Promise.all([
        employmentDetailPromise,
        openLoanDetailsPromise,
        customerDetailPromise,
        affordabilityPromise,
      ]);
      setEmploymentDetailsResponse(employmentDetailResponse);
      setOpenLoanDetailsResponse(openLoanDetailsResponse);
      setCustomerDetailResponse(customerDetailResponse);
      setAffordabilityDetailsResponse(affordabilityResponse);
      await updateSalaryDay(employmentDetailResponse);
      setIsLoading(false);
    } catch (ex) {
      displaySnackBar('Oops, an error has occurred please try again', 'error');
    }
    // eslint-disable-next-line
  }, [accountClient, loanClient, displaySnackBar]);

  const reloadCustomerData = async () => {
    try {
      const employmentDetailPromise = accountClient.getEmployment();
      const openLoanDetailsPromise = loanClient.getOpenLoanDetails(undefined);
      const customerDetailPromise = accountClient.getCustomer();
      const [employmentDetailResponse, openLoanDetailsResponse, customerDetailResponse] = await Promise.all([
        employmentDetailPromise,
        openLoanDetailsPromise,
        customerDetailPromise,
      ]);
      setEmploymentDetailsResponse(employmentDetailResponse);
      setOpenLoanDetailsResponse(openLoanDetailsResponse);
      setCustomerDetailResponse(customerDetailResponse);
      await updateSalaryDay(employmentDetailResponse);
      setIsLoading(false);
    } catch (ex) {
      displaySnackBar('Oops, an error has occurred please try again', 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleChangeEmploymentDetails = () => {
    showChangeEmploymentDetails.current = !showChangeEmploymentDetails.current;
    setIsEditing((prev) => !prev);
  };

  const toggleChangeSalaryDay = () => {
    showChangeSalaryDay.current = !showChangeSalaryDay.current;
    setIsEditing((prev) => !prev);
  };

  const onCloseEditing = async () => {
    showChangeEmploymentDetails.current = false;
    showChangeSalaryDay.current = false;
    await reloadCustomerData();
    setIsEditing(false);
  };

  const getSalaryDayFromLocalStorage = (): string => {
    const applyValues = localStorage.getItem('applyValues');
    if (applyValues) {
      const salaryDay = JSON.parse(applyValues)['salaryDay'];
      return `${salaryDay == 0 ? '' : salaryDay}`;
    }
    return '';
  };

  const salaryDayString =
    getSalaryDayFromLocalStorage().trim() !== ''
      ? getSalaryDayFromLocalStorage()
      : employmentDetailsResponse?.employment?.salaryDay
      ? employmentDetailsResponse.employment!.salaryDay!.toString()
      : '';

  const employmentDetailsContent = (
    <>
      <Typography variant="h2" fontSize={{ xs: '2.4rem', sm: '3.2rem' }} marginBottom={'2.4rem'} fontWeight={400}>
        Income
      </Typography>
      <Grid container marginLeft={'0rem'}>
        <Grid
          item
          xs={FULL_COLUMN_SIZE}
          xl={LABEL_COLUMN_SIZE}
          lg={LABEL_COLUMN_SIZE}
          md={LABEL_COLUMN_SIZE}
          sm={LABEL_COLUMN_SIZE}
          sx={{ marginTop: isMobile ? '0' : '1.6rem' }}
        >
          <label style={{ paddingLeft: 0, fontWeight: 300 }}>Gross monthly income (before tax)</label>
        </Grid>
        <Grid
          item
          xs={FULL_COLUMN_SIZE}
          xl={INPUT_COLUMN_SIZE}
          lg={INPUT_COLUMN_SIZE}
          md={INPUT_COLUMN_SIZE}
          sm={INPUT_COLUMN_SIZE}
        >
          {' '}
          <InputDisplayOnly
            type="text"
            value={`R ${
              employmentDetailsResponse.employment?.grossIncome !== undefined
                ? employmentDetailsResponse.employment?.grossIncome
                : ''
            }`}
          />
        </Grid>

        <Grid
          item
          xs={FULL_COLUMN_SIZE}
          xl={LABEL_COLUMN_SIZE}
          lg={LABEL_COLUMN_SIZE}
          md={LABEL_COLUMN_SIZE}
          sm={LABEL_COLUMN_SIZE}
          sx={{ marginTop: isMobile ? '0' : '1.6rem' }}
        >
          <label style={{ paddingLeft: 0, fontWeight: 300 }}>Nett monthly income (after tax)</label>
        </Grid>

        <Grid
          item
          xs={FULL_COLUMN_SIZE}
          xl={INPUT_COLUMN_SIZE}
          lg={INPUT_COLUMN_SIZE}
          md={INPUT_COLUMN_SIZE}
          sm={INPUT_COLUMN_SIZE}
        >
          <InputDisplayOnly
            type="text"
            value={`R ${
              employmentDetailsResponse.employment?.netIncome !== undefined
                ? employmentDetailsResponse.employment?.netIncome
                : ''
            }`}
          />
        </Grid>
        {!isEditing && (
          <>
            <Grid
              item
              xs={FULL_COLUMN_SIZE}
              xl={LABEL_COLUMN_SIZE}
              lg={LABEL_COLUMN_SIZE}
              md={LABEL_COLUMN_SIZE}
              sm={LABEL_COLUMN_SIZE}
            />

            <Grid
              item
              xs={FULL_COLUMN_SIZE}
              xl={INPUT_COLUMN_SIZE}
              lg={INPUT_COLUMN_SIZE}
              md={INPUT_COLUMN_SIZE}
              sm={INPUT_COLUMN_SIZE}
              style={{ height: '60px' }}
              display="flex"
              alignItems="flex-start"
            >
              {allowedToUpdate && (
                <TextButton removePadding onClick={toggleChangeEmploymentDetails}>
                  Change your income
                </TextButton>
              )}
            </Grid>
            <Grid
              item
              xs={FULL_COLUMN_SIZE}
              xl={FULL_COLUMN_SIZE}
              lg={FULL_COLUMN_SIZE}
              md={FULL_COLUMN_SIZE}
              sm={FULL_COLUMN_SIZE}
            >
              <hr className={sharedStyles['divider']} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );

  const changeEmploymentDetailsContent = (
    <>
      <ChangeEmploymentDetailsLN
        customerDetails={customerDetailResponse}
        employmentDetails={employmentDetailsResponse}
        onCloseEditing={onCloseEditing}
      />
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={FULL_COLUMN_SIZE}
        lg={FULL_COLUMN_SIZE}
        md={FULL_COLUMN_SIZE}
        sm={FULL_COLUMN_SIZE}
      >
        <hr className={sharedStyles['divider']} />
      </Grid>
    </>
  );

  const salaryDayContent = (
    <Grid container marginLeft={'0rem'}>
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={LABEL_COLUMN_SIZE}
        lg={LABEL_COLUMN_SIZE}
        md={LABEL_COLUMN_SIZE}
        sm={LABEL_COLUMN_SIZE}
        sx={{ marginTop: isMobile ? '0' : '1.6rem' }}
      >
        <label style={{ paddingLeft: 0, fontWeight: 300 }}>Salary day</label>
      </Grid>

      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={INPUT_COLUMN_SIZE}
        lg={INPUT_COLUMN_SIZE}
        md={INPUT_COLUMN_SIZE}
        sm={INPUT_COLUMN_SIZE}
      >
        <InputDisplayOnly value={salaryDayString} />
      </Grid>
      {!isEditing && (
        <>
          <Grid
            item
            xs={FULL_COLUMN_SIZE}
            xl={LABEL_COLUMN_SIZE}
            lg={LABEL_COLUMN_SIZE}
            md={LABEL_COLUMN_SIZE}
            sm={LABEL_COLUMN_SIZE}
          />
          <Grid
            item
            xs={FULL_COLUMN_SIZE}
            xl={INPUT_COLUMN_SIZE}
            lg={INPUT_COLUMN_SIZE}
            md={INPUT_COLUMN_SIZE}
            sm={INPUT_COLUMN_SIZE}
            style={{ height: '60px' }}
            display="flex"
            alignItems="flex-start"
          >
            {allowedToUpdate && (
              <TextButton removePadding onClick={toggleChangeSalaryDay}>
                Change your salary day
              </TextButton>
            )}
          </Grid>
        </>
      )}
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={FULL_COLUMN_SIZE}
        lg={FULL_COLUMN_SIZE}
        md={FULL_COLUMN_SIZE}
        sm={FULL_COLUMN_SIZE}
      >
        <hr className={sharedStyles['divider']} />
      </Grid>
    </Grid>
  );

  const changeSalaryDayContent = (
    <>
      <ChangeSalaryDayLN employmentDetails={employmentDetailsResponse} onCloseEditing={onCloseEditing} />
      <Grid
        item
        xs={FULL_COLUMN_SIZE}
        xl={FULL_COLUMN_SIZE}
        lg={FULL_COLUMN_SIZE}
        md={FULL_COLUMN_SIZE}
        sm={FULL_COLUMN_SIZE}
      >
        <hr className={sharedStyles['divider']} />
      </Grid>
    </>
  );

  if (isLoading) return <Loading text="Please wait while we retrieve your details" />;

  return (
    <>
      <Container maxWidth="lg" sx={{ padding: { md: '1.6rem 5rem 0rem 5rem', xs: '0.6rem 2rem 0rem 2rem' } }}>
        {!isLoading && employmentDetailsResponse && (
          <>
            {employmentDetailsResponse &&
              (!showChangeEmploymentDetails.current ? employmentDetailsContent : changeEmploymentDetailsContent)}
            {!showChangeSalaryDay.current ? salaryDayContent : changeSalaryDayContent}
          </>
        )}
      </Container>
      <Container maxWidth="lg" sx={{ padding: { md: '0rem 5rem 0rem 5rem', xs: '0rem 2rem 0rem 2rem' } }}>
        <ChangeAffordabilityLN
          employmentDetailResponse={employmentDetailsResponse}
          affordabilityDetailsResponse={affordabilityDetailsResponse}
          isFetchingData={isLoading}
          isEditing={isEditing} />
      </Container>
    </>
  );
};
export default ConfirmAffordabilityDetailsPage;
